import React, { useState } from "react";
import axios from "axios";
import { Button, TextField, Box, Typography, Stack } from "@mui/material";

const apiUrl = process.env.REACT_APP_API_URL; // Base URL from environment variables
const environment = process.env.NODE_ENV; // Determine the current environment

const Loller = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const getAuthHeader = () => ({
    headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    },
  });

  const handleDropTables = async () => {
    try {
      const response = await axios.post(`${apiUrl}/loller/drop-tables`, null, getAuthHeader());
      alert(response.data.message);
    } catch (error) {
      handleError(error);
    }
  };

  const handleInitDb = async () => {
    try {
      const response = await axios.post(`${apiUrl}/loller/init-db`, null, getAuthHeader());
      alert(response.data.message);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    console.error("Error details:", error); // Log the error for debugging

    if (error.response && error.response.data) {
      const detail = error.response.data.detail || JSON.stringify(error.response.data);
      alert(`Error: ${detail}`);
    } else {
      alert(`Error: ${error.message || "An unknown error occurred"}`);
    }
  };

  return (
    <Box className="loller-page" sx={{ p: 3, maxWidth: 500, margin: "auto", textAlign: "center" }}>
      <Typography variant="h4" gutterBottom>
        Admin Page
      </Typography>
        <Box sx={{ mb: 3, mt: 2 }}>
          <TextField
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Box>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        sx={{ marginTop: 10 }} // Add marginTop here
      >
        <Button
          variant="contained"
          color="success"
          onClick={handleInitDb}
        >
          Initialize Database
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDropTables}
        >
          Drop Tables
        </Button>
      </Stack>
    </Box>
  );
};

export default Loller;
